<script>
import { mapActions } from 'vuex'

export default {
  name: 'Levels',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      levelsList: [],
      pagination: {
        page: 1
      }
    }
  },

  computed: {
    pageItems () {
      return this.levelsList.slice((this.pagination.page - 1) * 12, ((this.pagination.page - 1) * 12) + 12)
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([ this.getLevelsList() ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptGetLevelsList'
    ]),

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.getLastPage
    },

    nextPage () {
      if (this.pagination.page < this.getLastPage) {
        this.pagination.page += 1
      }
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    getLevelsList () {
      return this.attemptGetLevelsList({}).then(result => {
        if (result.data) {
          result.data.forEach(element => {
            this.levelsList.push({
              level: element.position,
              points: element.points
            })
          })

          return true
        }
      })
    },

    getLastPage () {
      return this.countPages(this.levelsList.length, 12)
    }
  }
}
</script>

<template>
  <div
    v-if="pageItems.length > 0"
    class="center level-table"
  >

    <div class="info-instructions">
      <h2 class="info">
        O nivelamento de usuários seguirá a regra: PN = PNa + (Na * 100), onde: 
      </h2>
      <div class="instructions">
        <p>PN = Pontuação do novo nível</p>
        <p>PNa = Pontuação do nível anterior</p>
        <p>Na = Nível anterior</p>
      </div>
    </div>

    <Datatable
      :class="{'first-page': pagination.page === 1}"
      :items="pageItems"
    >
      <template slot="thead">
        <tr>
          <th class="th">
            {{ $t('level.list:datatable.header.1') }}
          </th>
          <th class="th">
            {{ $t('level.list:datatable.header.2') }}
          </th>
        </tr>
      </template>
      <template
        slot="tbody"
        slot-scope="props"
      >
        <tr>
          <td class="td">
            <span class="td-text">{{ props.item.level }}</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.points }}</span>
          </td>
        </tr>
      </template>
    </Datatable>
    <Pagination
      :active-page="pagination.page"
      :page-count="getLastPage"
      @firstPage="firstPage"
      @lastPage="lastPage"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style>
.level-table .datatable .th:nth-child(1) {
  padding-left: 15px;
}
.level-table .datatable .td:nth-child(1) {
  padding: 16px 30px;
}

.info-instructions {
  margin-bottom: 40px;
}

.info-instructions .info {
  color: #005EB8;
  padding: 0;
  margin: 20px 0;
  text-transform: none;
}

.info-instructions .instructions {
  color: #fff;
  background: #005EB8;
  border-radius: 15px;
  padding: 20px;
}

.info-instructions .instructions h2 {
  color: #fff;
  padding: 0;
  margin: 0 0 20px 0;
}

.info-instructions .instructions p {
  font-size: 15px;
  padding: 2px 0 2px 25px;
}
</style>
